$primary: #092E6E;
$text: #6F6F6F;

@font-face {
  font-family: gothamBlack;
  src: url("../fonts/gotham/Gotham-Black.otf");
}
@font-face {
  font-family: gothamBold;
  src: url("../fonts/gotham/Gotham-Bold.otf");
}
@font-face {
  font-family: gothamMedium;
  src: url("../fonts/gotham/Gotham-Medium.otf");
}
@font-face {
  font-family: gothamLight;
  src: url("../fonts/gotham/Gotham-Light.otf");
}

body,
html {
	padding: 0;
	margin: 0;
	font-family: "gothamLight", "arial", sans-serif;
	&.lock {
		overflow: hidden;
	}
}
h1,h2,h3,h4,h5,h6 {
	color: $primary;
	font-family: "gothamBold", "arial", sans-serif;
}
p {
	color: $text;
	font-size: 0.8rem;
	line-height: 1.2rem;
}
button,
a {
	font-family: "gothamLight", "arial", sans-serif;
	&:hover {
		cursor: pointer;
	}
	&:focus,
	&:active {
		outline: none;
		border: none;
		box-shadow: none;
	}
}
header {
	background: rgba(9, 46, 110, 1);
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 999;
	box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
	svg {
		fill: white;
		max-height: 50px;
		&.logo {
			width: 150px;
			height: auto;
			&:hover {
				cursor: pointer;
			}
		}
		&.icon {
			width: 30px;
			height: auto;
		}
	}
	.bar {
		display: flex;
		justify-content: space-between;
		padding: 10px 20px;
		margin: 0 auto;
		article {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: nowrap;
			span {
				white-space: nowrap;
				font-weight: bolder;
				text-transform: uppercase;
				letter-spacing: 3px;
				font-size: 0.8rem;
				margin-left: 12px;
				padding-left: 15px;
				position: relative;
				color: white;
				font-family: "gothamBlack", sans-serif;
				line-height: 30px;
				&:before {
					content:"";
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					width: 1px;
					background: white;
				}
			}
		}
	}
}
footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	padding: 20px;
	font-family: gothamMedium;
	button,
	span {
		margin: 20px 0;
		text-transform: uppercase;
		font-size: 0.5rem;
		letter-spacing: 0.5px;
		color: $primary;
		text-align: center;
		font-family: gothamMedium;
	}
	button {
		border: none;
		background: none;
		font-weight: bolder;
		font-size: 0.8rem;
		letter-spacing: 1px;
		&.disabled {
			color: #6F6F6F;
			cursor: not-allowed;
				pointer-events: all !important;
		}
		&:hover {
			color: #6F6F6F;
			transition: all 0.3s ease-in-out;
		}
	}
}
#pageWrap {
	#homePage {
		overflow-x: scroll;
	}
	#innerPageTemplate {
		position: fixed;
		z-index: 10;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		overflow-x: scroll;
		padding: 50px 0 0;
		background: white;
		transform: translate(0, -120vh);
		transition: all 0.3s ease-in-out;
		cursor: auto;
	}
	&.inner {
		#innerPageTemplate {
			transform: translate(0,0);
			transition: all 0.3s ease-in-out;
		}
	}
}
.mainBanner {
	width: 100%;
	height: 0;
	padding-top: 56.25%;
	background: black;
	position: relative;
	background-size: cover;
	background-position: center;
	video {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		cursor: url('/assets/img/audio-on.png'), move;
		&.sound {
			cursor: url('/assets/img/audio-off.png'), move;
		}
	}
	.play {
		pointer-events: none;
	}
}
#projectListing {
	margin: 10px auto;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 0 5px;
	box-sizing: border-box;
	.project {
		width: 50%;
		padding: 5px;
		box-sizing: border-box;
		article {
			position: relative;
			overflow: hidden;
			&:before {
				content: "";
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: $primary;
				opacity: 0;
				transition: all 0.3s ease-in-out;
			}
			&:hover {
				cursor: pointer;
				&:before {
					opacity: 0.6;
					transition: all 0.3s ease-in-out;
				}
				.logo {
					opacity: 1;
					transform: translate(-50%,-50%) scale(1);
					transition: all 0.5s ease-in-out;
				}
				.poster {
					filter: blur(4px);
					transition: all 0.3s ease-in-out;
				}
			}
			.logo {
				width: 80%;
				position: absolute;
				z-index: 3;
				top: 50%;
				left: 50%;
				opacity: 0;
				transform: translate(-50%,-50%) scale(0.9);
				transition: all 0.3s ease-in-out;
			}
			.poster {
				margin-bottom: -5px;
				width: 100%;
				position: relative;
				z-index: 1;
				filter: blur(0);
				transition: all 0.3s ease-in-out;
			}
		}
	}
}
.wrap {
	width: 100%;
	box-sizing: border-box;
}
#innerModal {
	#videoRatioWrap {
		height: 0;
  		width: 100%;
		padding-top: 56.25%;
		position: relative;
		transform: scale(1);
		iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
}
.play {
	background: no-repeat scroll center / contain rgba(0,0,0,0.3);
	background-size: 40%;
	background-position: 60% 50%;
	display: block;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	border: 3px solid white;
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: 1;
	transform: translate(-50%,-50%) scale(1);
	transition: all 0.3s ease-in-out;
}
@media (min-width: 768px) {
	header {
		svg {
			&.logo {
				width: 200px;
			}
		}
		.bar {
			article {
				span {
					font-size: 1rem;
					letter-spacing: 5px;
				}
			}
		}
	}
	.wrap,
	#projectListing {
		margin: 20px auto;
		padding: 0 20px;
		.project {
			padding: 10px;
			width: 33.3333333%;
		}
	}
	footer {
		flex-direction: row;
		margin-bottom: 20px;
		button,
		span {
			margin: 0 20px;
			font-size: 0.6rem;
			transition: all 0.3s ease-in-out;
		}
	}
	#innerModal {
		#videoRatioWrap {
			transform: scale(0.7);
		}
	}
}
@media (min-width: 812px) {
	.mainBanner {
		.play {
			display: none;
		}
	}
}
@media (min-width: 991px) {
	header {
		.bar {
			width: 900px;
		}
	}
	#projectListing,
	.wrap {
		width: 800px;
	}
}
@media (min-width: 1024px) {
	header {
		.bar {
			width: 991px;
		}
	}
	#projectListing,
	.wrap {
		width: 991px;
		.project {
			width: 25%;
		}
	}
}
@media (min-width: 1200px) {
	header {
		.bar {
			width: 1200px;
		}
	}
	#projectListing,
	.wrap {
		width: 1024px;
	}
}
@media (min-width: 1200px) {
	#projectListing,
	.wrap {
		width: 1200px;
	}
}